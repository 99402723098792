import React from 'react';

import { 
    Box, 
    Tabs, 
    Tab, 
    Typography,
    Grid,
    Hidden
} from '@material-ui/core';

import { TabPanel } from '../../../../shared/components/Tabs';
import SubjectsPerQuarter from './SubjectsPerQuarter/SubjectsPerQuarter';
import OptionalSubjects from './OptionalSubjects/OptionalSubjects';
import { convertSourceToImageResource } from '../../../../design-system/utils/placeholderImages';
import { COMPANIES } from '../../../../design-system/constants/sources';
import StudentRegulation from '../../../../design-system/components/StudentRegulation/StudentRegulation';
import ProgressAccordionDetail from '../../SyncProgram/components/ProgressAccordionDetail';
import AccordionProgramSummary from './components/AccordionProgramSummary/AccordionProgramSummary';

import { makeStyles } from '@material-ui/core/styles';

import { STATUS_COURSES } from "@sdk-point/talisis";
import DownloadGradesButton from './components/DownloadGradesButton/DownloadGradesButton';

const STATUS_IN_PROGRESS = 1;
const ENROLLED_STATUS_ID = 3;
const TYPE_OPTATIVE = 3;

const useStyles = makeStyles((theme) => ({
    middlePadding:{
        paddingRight: 56,
        [theme.breakpoints.down('md')]:{
            paddingRight: 28
        }
    }
}))

function getOptativesArray(optativesCount, {level_id, company_id}, area_priority) {
    let optativesArray = [];
    const prop = company_id === COMPANIES.UERRE ? company_id : `${level_id}-${company_id}-${area_priority}`;
    const maxOptatives = {
        [COMPANIES.UERRE]: 1,
        'LI-UNID-8': 5,
        // 'LI-UNID-2': 1,
    };

    if(maxOptatives.hasOwnProperty(prop)){
        // si remainingOptatives es resultado negativo, se asigna 0
        const remainingOptatives = Math.max(maxOptatives[prop] - optativesCount, 0);
        optativesArray = [...Array(remainingOptatives).keys()].map(i => `Optativa ${optativesCount + i + 1}`);
    }
    
    return optativesArray;
}
  

function groupCoursesByCuatrimestre(program, data) {
    const cuatrimestreLabels = {
      10: '1er cuatrimestre',
      20: '2do cuatrimestre',
      30: '3er cuatrimestre',
      40: '4to cuatrimestre',
      50: '5to cuatrimestre',
      60: '6to cuatrimestre',
      70: '7mo cuatrimestre',
      80: '8vo cuatrimestre',
      90: '9no cuatrimestre',
    };
  
    // Un objeto para agrupar los cursos por cuatrimestre
    const groupedCourses = {};
    let optativesCat = [];
  
    // Iteramos sobre las secciones de data
    data.forEach(section => {
        section.courses.forEach(course => {
            const cuatrimestreLabel = cuatrimestreLabels[course.area_priority] || null;
            course.status = section.status === TYPE_OPTATIVE ? course.status : section.status;
            course.tagLabel = !cuatrimestreLabel ? 'Curso' : cuatrimestreLabel; 
    
            course.image_url = convertSourceToImageResource(course.company_id, course.source_id);
    
            // Solo agregamos el curso si tiene un cuatrimestre válido
            if (cuatrimestreLabel) {
                // Si el grupo del cuatrimestre no existe, lo creamos
                if (!groupedCourses[cuatrimestreLabel]) {
                    groupedCourses[cuatrimestreLabel] = [];
                }
    
                // Añadimos el curso al grupo correspondiente excluyendo optativas no enroladas
                const isEnrolledOptative = course.is_optative && course.enrollment_status_id === ENROLLED_STATUS_ID;
                if (!course.is_optative || isEnrolledOptative) {
                    groupedCourses[cuatrimestreLabel].push(course);
                }
            }
    
            // Añadimos el curso a optativesCat si es una optativa
            if (course.is_optative) {
                optativesCat.push(course);
            }
        });
    });
  
    // Ordenamos los cuatrimestres según el área de prioridad
    const sortedCuatrimestres = Object.keys(groupedCourses).sort((a, b) => {
        const priorityA = Object.keys(cuatrimestreLabels).find(key => cuatrimestreLabels[key] === a);
        const priorityB = Object.keys(cuatrimestreLabels).find(key => cuatrimestreLabels[key] === b);
        return priorityA - priorityB;
    });
  
    // Convertimos el objeto en un array de objetos agrupados por cuatrimestre
    const cuatrimestresArray = sortedCuatrimestres.map((cuatrimestre, index) => {
        const id = index + 1;
        const area_priority = parseInt(Object.keys(cuatrimestreLabels).find(key => cuatrimestreLabels[key] === cuatrimestre), 10);
        const courses = groupedCourses[cuatrimestre];
        const optativesCount = groupedCourses[cuatrimestre].filter(item => item.is_optative).length;

        console.log('optativesCount', optativesCount);
        const optatives = getOptativesArray(optativesCount, program, id);
        console.log('optativesCount', optatives);
        
        // Determinamos el status del cuatrimestre:
        const allApproved = courses.every(course => course.approved === true && course.status === STATUS_COURSES.COMPLETED);
        const inProgress = courses.some(course => course.status === 1);
        const someEnrolled = courses.some(course => course.enrollment_status_id === ENROLLED_STATUS_ID);
        const hasNewSubject = courses.some(course => course.isNew);
        const enableOptatives = courses.some(course => course.isEnrrollment) && optativesCat.some(course => course.isEnrrollment);

        let status = 2; // Pendiente por defecto
        if (allApproved) {
            status = 0; // Completado
        }else if (inProgress || someEnrolled) {
            status = STATUS_IN_PROGRESS; // En progreso
        }

        const coursesCompleted = courses.filter(it => it.status === STATUS_COURSES.COMPLETED && it.approved).length;
  
        return {
            id, // Agregar ID secuencial
            area_priority,
            label: cuatrimestre,
            courses,
            optatives,
            status,
            subjectsInProgress: inProgress,
            coursesCompleted,
            totalCourses: courses.length + optatives.length,
            hasNewSubject,
            enableOptatives
        };
    });

    
    const areaPriorityEnroll = cuatrimestresArray.find(cuatrimestre => cuatrimestre.optatives.length > 0)?.area_priority || null;

    // Determinamos el cuatrimestre activo según las condiciones
    let activeCuatrimestre = null;

    // 1. Intentamos encontrar el cuatrimestre más alto con curso en progreso
    activeCuatrimestre = cuatrimestresArray.slice().find(cuatrimestre => cuatrimestre.subjectsInProgress);

    if (!activeCuatrimestre) {
        activeCuatrimestre = cuatrimestresArray.slice().find(cuatrimestre => cuatrimestre.status === STATUS_COURSES.IN_PROGRESS);
    }
  
    // 2. Si no hay cuatrimestre en progreso, buscamos el que sigue después del último completado
    if (!activeCuatrimestre) {
        const lastCompletedIndex = cuatrimestresArray.findIndex(cuatrimestre => cuatrimestre.status === 0);
        if (lastCompletedIndex !== -1 && lastCompletedIndex + 1 < cuatrimestresArray.length) {
            activeCuatrimestre = cuatrimestresArray[lastCompletedIndex + 1];
        }
    }
  
    // 3. Si todos los cuatrimestres están completados, marcamos como activo el primero
    if (!activeCuatrimestre) {
        activeCuatrimestre = cuatrimestresArray[0];
    }
  
    // Marcamos el cuatrimestre activo y ajustamos los cuatrimestres anteriores no completados
    const groupedCoursesByStatus = cuatrimestresArray.map(cuatrimestre => {
        const active = cuatrimestre === activeCuatrimestre;
        
        // Si el cuatrimestre es anterior al activo y no está completado, lo marcamos como "in_progress"
        if (!active && cuatrimestre.status === 2) {
            const activeIndex = cuatrimestresArray.findIndex(cuatrimestre => cuatrimestre.active);
            const currentIndex = cuatrimestresArray.findIndex(c => c.label === cuatrimestre.label);
            
            if (currentIndex < activeIndex) {
            cuatrimestre.status = 1; // Marcamos como en progreso
            }
        }
    
        return {
            ...cuatrimestre,
            active,
        };
    });

    return {
        groupedCoursesByStatus,
        optativesCat, 
        areaPriorityEnroll
    }
}

const AsyncProgramDetail = ({program = null, data = [], userMembership, acceptedCompanyRegulation, onClickAcceptRegulation, reportGrades}) => {
    const classes = useStyles();

    const [value, setValue] = React.useState(0);
    const {groupedCoursesByStatus:groupedCourses, optativesCat: optatives, areaPriorityEnroll} = groupCoursesByCuatrimestre(program, data);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box py={4}>
            <Grid container spacing={0}>
                <Grid item xs={12} md={8} lg={9} className={classes.middlePadding}>
                    <Box height={160}>
                        <Typography variant="h1" className="text-white mb-0">{program.name} </Typography>
                    </Box>
                    <Box id="async-program-detail-root">
                        <Tabs aria-label="async program tabs" value={value} onChange={handleChange} indicatorColor="primary">
                            <Tab label="Todas las materias" />
                            <Tab label="Catálogo de optativas" />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                            <Typography variant='h4'>Programa completo</Typography>
                            <SubjectsPerQuarter 
                                data={groupedCourses} 
                                userMembership={userMembership} 
                                credits={program.credits}
                                onClickSelect={() => setValue(1)}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <OptionalSubjects data={optatives} userMembership={userMembership} credits={program.credits} areaPriorityEnroll={areaPriorityEnroll}/>
                        </TabPanel>
                    </Box>
                </Grid>
                <Grid item xs={3} md={4} lg={3}>
                    <Hidden smDown>
                        <Box display='flex' gridGap={24} flexDirection='column'>
                            <StudentRegulation elevation={8}
                                companyId={program.company_id}  
                                accepted={acceptedCompanyRegulation}
                                onClickAccept={onClickAcceptRegulation} 
                                width={324}
                                minWidth={324}
                            />
                            <ProgressAccordionDetail credits={program.credits} level_id={program.level_id} />
                            <AccordionProgramSummary list={groupedCourses}/>
                            <Typography variant="body1">Este listado excluye materias fuera de plan y revalidaciones.</Typography>
                            <DownloadGradesButton companyId={program.company_id} reportGrades={reportGrades}/>
                        </Box>

                    </Hidden>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AsyncProgramDetail;