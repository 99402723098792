import React from 'react';

import { Box, Button, Icon, Typography, IconButton, Card, Link } from '@material-ui/core';
import LogoSource from '../../components/LogoSource/LogoSource';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import clsx from "clsx";

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { convertOuToSource } from '../../utils/source';

const useStyles = makeStyles((theme) => ({
    root:{
        // border: `0.5px solid ${theme.palette.colors.warning[300]}`,
        display: 'flex',
        gap: 16,
        padding: 16,
        borderRadius: 8,
        flexDirection: 'column',
        '&.accepted':{
            border: `0.5px solid ${theme.palette.colors.fillStroke[200]}`,
        }
    },
    dialogTitle:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        borderBottom: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        '& button':{
            position: 'absolute',
            right: 0
        }
    },
    dialog:{
        '& .MuiDialog-paper':{
            maxWidth: 506,
            background: theme.palette.colors.fillStroke[500],
            border: `0.5px solid ${theme.palette.colors.fillStroke[200]}`,
        }
    },
    dialogContent:{
        display: 'flex',
        gap: 20,
        flexDirection: 'column',
        paddingTop: 16
    }
}))

const GreenCheckbox = withStyles((theme) => ({
    root: {
        color: theme.palette.colors.warning[300],
        padding: 4,
        '&$checked': {
            color: theme.palette.colors.success[300],
        },
        '& .MuiSvgIcon-root':{
            width: 21,
            height: 21
        }
    },
    checked: {},
}))((props) => <Checkbox color="default" {...props} />);

const StudentRegulation = ({companyId='', accepted = null, onClickAccept, ...rest}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [confirm, setConfirm] = React.useState(false);
    const [source, setSource] = React.useState(null);

    React.useEffect(()=>{
        if(companyId){
            setSource(convertOuToSource(companyId))
        }
    }, [companyId])
    
    const handleChange = (event) => {
        if(!accepted){
            setOpen(true);
        }
    };
    const handleChangeConfirm = (event) => {
        setConfirm(true)
    };

    const handleClose = () => {
        setOpen(false);
        setConfirm(false)
    };

    const handleClickAccept = () => {
        onClickAccept();
        handleClose();
    };

    const handleClickDownload = async () => {
        const URLS = {
            UNID: 'https://digitalmedia.talisis.com/unid/reglamento-alumnos/2024.pdf',
            UERRE: 'https://digitalmedia.talisis.com/u-erre/reglamento-institucional/federal-2024.pdf'
        }

        if (!URLS.hasOwnProperty(companyId)) return null;

        try {
            let anchor = document.createElement('a');
            anchor.href = URLS[companyId];
            anchor.target = '_blank';
            anchor.download = 'Reglamento.pdf';
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        } catch (error) {
            console.log('error', error);
        }
    }
    
    if(!companyId) return null;

    return (
        <>
            <Card className={clsx(classes.root, {'accepted': accepted})} component={Box} {...rest}>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <LogoSource color="white" sourceId={source} style={{height: 24}}/>
                    {
                        typeof accepted === 'boolean' &&
                        <FormControlLabel
                            style={{flexDirection: 'row-reverse', marginRight: 0}}
                            control={<GreenCheckbox checked={accepted} onChange={handleChange} name="checkedG" disable={accepted}/>}
                            label={accepted 
                                ? <Typography className='variant-xsmall text-success-300'>Leído y aceptado</Typography>
                                : <Typography className='variant-xsmall text-white'>Marcar como leído</Typography>
                            }
                            disable={accepted}
                        />
                    }
                </Box>
                <Typography className='variant-xsmall text-white'>Consulta el reglamento de nuestra institución para conocer las normas y políticas.</Typography>
                <Button variant='contained' 
                    startIcon={<Icon className='ri-download-line'/>}
                    onClick={handleClickDownload}
                >
                Leer reglamento de alumno
                </Button>
            </Card>

            <Dialog onClose={handleClose} className={classes.dialog} open={open}>
                <MuiDialogTitle disableTypography className={classes.dialogTitle}>
                    <Typography variant="subtitle1">Reglamento de alumno</Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose} disableRipple>
                        <Icon className='ri-close-line text-fill-stroke-100 font-size-24' />
                    </IconButton>
                </MuiDialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Typography>
                        Por favor, marca la casilla para confirmar que has leído y aceptas el 
                        <Link variant="body1" color='secondary' component="button" onClick={handleClickDownload} underline='always' className='ml-1'>
                            reglamento institucional
                        </Link>.
                    </Typography>
                    <FormControlLabel
                        control={<Checkbox checked={confirm} onChange={handleChangeConfirm} name="checkedG" color='primary' />}
                        label={<Typography>
                            Confirmo que he leído y acepto el reglamento de alumno.
                        </Typography>
                        }
                    />
                </DialogContent>
                <DialogActions className='justify-center padding-2'>
                    <Button onClick={handleClickAccept} variant='outlined' color='secondary' disabled={!confirm}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default StudentRegulation;