import React, { useEffect, useState, lazy, Suspense } from "react";
import { useHistory } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react/swiper-react.js';
import moment from "moment";

// @material-ui
import { Box, Typography, Button, Icon, Card, CardContent, Dialog, DialogTitle } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import Collapse from '@material-ui/core/Collapse';

// @sdk
import { AllysService, CourseService, HelpCenterService, LogsService, ProgramService, MembershipService, VoxyService, ProductService } from "@sdk-point/talisis";

// @shared
import Loader from '../../../shared/components/Loader/LoaderContent';
import CoursesProvider, { useCoursesContext } from '../../../shared/components/CourseCarousel/context/CoursesProvider';
import { types } from '../../../shared/components/CourseCarousel/context/CoursesReducer';

import TitleLink from '../../../shared/components/TitleLink/TitleLink';
import SlickCarousel from '../../../shared/components/SlickCarousel/SlickCarousel';
import AcademiesSection from "./AcademiesSection/AcademiesSection";
import NoCoursesFound from './components/NoCoursesFound';
import { convertOuToSource } from '../../../../src/constants/convertOuToSource';
import { getSessionCookie } from "../../../shared/utils/Sessions";
import { Mixpanel } from '../../../shared/components/Mixpanel/mixpanel';
import getConnectionAllies from '../../../shared/components/Allies/AlliesConnect';
import { SOURCES } from "../../../shared/constants/sources";
import { MEMBERSHIPS } from "../../../shared/constants/memberships";
import { log } from '../../../shared/utils/console.js'
import dateFormat from "../../../design-system/utils/dateFormat";
import { COMPLETE_DATE_FORMAT_SHORT } from "../../../design-system/constants/date-formats";
import DisabledCourseCard from "../../../design-system/components/Cards/DisabledCourseCard/DisabledCourseCard";
import cursosError from '../../../assets/images/cursosError.svg';
import { eventTracker } from "../../../shared/components/EventTracker/EventTracker.js";

// @styles
import useStyles from '../../../assets/styles/jss/pages/courses/coursesInProgressStyle';

// Cart
import { addProductsToCart } from "../../../shared/utils/cartHelper.js";
import { getNameMembership, getPeriodMembership } from "../../../shared/utils/checkoutHelpers.js";

// @Redux
import { applyCart, applyCartProducts } from "../../../redux/actions/cartActions.js";

// Roles
import { hasRole } from "../../../services/RoleService";
import { ROLE_ACADEMY, ROLE_ESTUDIANTE, ROLE_MAESTRO } from "../../../shared/constants/roles";
import { useDispatch, useSelector } from "react-redux";
import { getLogoCard } from "../../../constants/logosImages";
import VoxyHHCard from "./VoxyHHCard";
import { CURSOS, CART } from "../../../routes/paths";
import OnStartMyClasses from "./components/OnStartMyClasses.jsx";
import BannerNotCourses from "./components/BannerNotCourses.jsx";

const CourseProgressCardBody = lazy(() => import('./components/CourseProgressCardBody'));
const CourseEnrollAsyncClass = lazy(() => import('./components/CourseEnrollAsyncClass'));

const AllysServiceSDK = new AllysService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const logsServiceSDK = new LogsService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const HelpCenterServiceSDK = new HelpCenterService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const courseService = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const MembershipServiceSDK = new MembershipService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const VoxyServiceSDK = new VoxyService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const isAvailableCourseByDate = (localDate, courseDate) => courseDate.diff(localDate, 'seconds') < 1;
const isDisabledCourseByDate = (localDate, courseDate) => courseDate.diff(localDate, 'seconds') > 0;

const CardsCoursesInProgress = () => {
    const history = useHistory();
    const classes = useStyles();
    const {dispatch} = useCoursesContext();
    const dispatchCart = useDispatch();
    const [programsInProgress, setProgramInProgress] = useState({ programs: [] });
    const [coursesEduconInProgress, setCoursesEduconInProgress] = useState({ courses: [] });
    const [courseAllysInProgress, setCoursesAllysInProgress] = useState({ courses: [] });
    const [userMembership, setUserMembership] = React.useState(null);
    const [userAddOn, setUserAddOn] = React.useState(null);
    const [voxyUserData, setVoxyUserData] = React.useState({});
    const [hhOnlineDialogOpen, setHHOnlineDialogOpen] = useState(false);
    const [redirectHHOnlineMessageError, setRedirectHHOnlineMessageError] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingHHOnline, setLoadingHHOnline] = useState(false);
    const [loadingRepurchase, setLoadingRepurchase] = useState(false);
    const CourseServiceSDK = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
    const ProgramServiceSDK = new ProgramService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
    const ProductServiceSDK = new ProductService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

    // NoCourses
    const [coursesSysError, setCoursesSysError] = React.useState(false);
    const [isRedirected, setIsRedirected] = React.useState(false)
    const [ticketSended, setTicketSended] = React.useState(false)
    const [allies, setAllies] = React.useState(null)
    const [open, setOpen] = React.useState(false);
    const [userHasCourses, setUserHasCourses] = React.useState(true);

    const [membershipRenovationCopyOpen, setMembershipRenovationCopyOpen] = React.useState(false);

    const role = JSON.parse(localStorage.getItem('permissions'));
    const userRole = (role != null && role?.length > 0) ? JSON.stringify(role[0]?.role_name).replace(/["']/g, "") : 'Estudiante'; // Maestro

    const localDate = moment();
    const { user } = useSelector((state) => state.userReducer);
    const { cart } = useSelector((state) => state.cartReducer);

    const getOU = (email) => {
        let ouId;
        const domain = email.substring(email.lastIndexOf("@") + 1);
        switch (domain) {
            case 'red.unid.mx' || 'unid.mx':
                ouId = 'UNID'
                break;
            case 'u-erre.mx' || 'u-erre.edu.mx' || 'iesalud.edu.mx':
                ouId = 'UERRE'
                break;
            case 'talisis.com':
                ouId = 'TAL'
                break;
            default:
                break;
        }
        return ouId;
    }

    useEffect(() => {
        //getData();
        getAllData();
        setMembershipRenovationCopyOpen(true);
        return () => {
            return getProgramsInProgress();
        }
    }, []);

    const getAllData = async () => {
        setLoading(true);

        const programData = await getProgramsInProgress();
        const allyData = await getCoursesAllyInProgress();
        const educonData = await getCoursesEduconInProgress();
        if (programData?.programs?.length > 0 || allyData?.courses?.length > 0 || educonData?.courses?.length > 0) {
            setUserHasCourses(() => true)
        } else {
            setUserHasCourses(() => false)
        }
        setProgramInProgress(() => programData);
        setCoursesAllysInProgress(() => allyData);
        setCoursesEduconInProgress(() => educonData);
        const personMembershipDetail = await MembershipServiceSDK.getPersonMembershipDetail(user.person_id);
        setUserMembership(personMembershipDetail.userMembership);
        setUserAddOn(personMembershipDetail.userAddOn);
        
        const voxyUserDataResponse = await VoxyServiceSDK.getExternalUserDataByIdTalisis(user.person_id);
        log(voxyUserDataResponse)
        setVoxyUserData(voxyUserDataResponse);
        setLoading(false);
    };

    const getProgramsInProgress = async () => {
        let response = { programs: []};

        try {
            await new Promise((resolve) => setTimeout(resolve, Math.random() < 0.5 ? 5000 : 6000));
            response = await ProgramServiceSDK.getProgramsInProgressByUser(user.person_id, user.student_id);

            dispatch({ type: types.ADD_PROGRAMS, payload: response.programs });
        }
        catch (error) {
            log('Error fetch programs in progress', error);
        }

        return response;
    };

    const getCoursesAllyInProgress = async () => {
        return await CourseServiceSDK.getCoursesAllyInProgressByUser(user.person_id);
    };

    const getCoursesEduconInProgress = async () => {
        const educonData = await CourseServiceSDK.getCoursesEduconInProgressByUser(user.person_id);

        return educonData;
    }

    const handleChangePage = (e, type_resource, id, student) => {
        e.preventDefault();
        student = (type_resource === 'programas') ? `/${student}` : '';
        history.push(`/${type_resource}/${id}/cursos${student}`);
    };

    const handleCourseSysError = async (error) => {
        const eventdata = {
            userId: user.person_id,
            message: error.reason,
            unidadOperativa: user.ou
        }
        //falta migrar evento a SDK

        if (error.end) {
            setCoursesSysError(true)
        }
        log(error)
    }

    const getData = async () => {
        try {
            let coursesData = [];
            setLoading(true);

            const data = await courseService.getPersonCoursesInProgress(user.person_id);
            if (data?.response?.length > 0) {
                coursesData = data.response.slice();
            }
            else {
                setUserHasCourses(false)
            }
            setLoading(false);
            // handleSetChargeStatusForOptimize("progress"); //! Borrar si no falla nada
            return coursesData;
        }
        catch (e) {
            log(e.message);
        }
    };

    const getShortcutsAllies = async () => {
        try {
            var request = {
                person_id: user.person_id
            }
            const shortcuts = await AllysServiceSDK.getShortcutsAllies(request);

            let neoAllies = [];
            neoAllies = shortcuts.filter(ally => ally.company_id === 'UERRE' || ally.company_id === 'UNID');
            if (neoAllies.length === 0) {
                let error;
                error = {
                    reason: 'No encontramos relacion del alumno con cursos - no allies'
                }
                handleCourseSysError(error)
                const log = { "person_id": user.person_id, "errortype_id": 4, "errororigin_id": 1 }
                await logsServiceSDK.logError(log)
                log('validacion usuarios 1')
                let neoUser;
                try {
                    neoUser = await AllysServiceSDK.validateNeoUser({ studentid: user.email, ou: getOU(user.email) })
                } catch (error) {
                    log('Error al validar usuario', error);
                }
                if (!neoUser?.neo_id) {
                    error = {
                        reason: 'Alumno no encontrado en Neo',
                        end: true
                    }
                    handleCourseSysError(error)
                    const log = { "person_id": user.person_id, "errortype_id": 3, "errororigin_id": 1 }
                    await logsServiceSDK.logError(log)
                    setLoading(false)
                }
                else {
                    const ally = {
                        "source_id": 4,
                        "company_id": user.company_id,
                        "AllyName": "NEO"
                    };
                    log(ally)
                    await handleGoToAllyNeo(ally)
                    // setAllies(ally)
                    setLoading(false)
                    // setIsRedirected(true)
                    log('redirigido si 95')
                }

            }
            else {
                setLoading(false);
                if (neoAllies.length < 2) {
                    setIsRedirected(true)
                    log('redirigido si 103')
                    await handleGoToAllyNeo(neoAllies[0])
                }
                else {
                    setAllies(neoAllies);
                }
            }
        }
        catch (err) {
            setLoading(false);
            log('catch', err)
        }
    }

    const handleGoToNeo = async () => {
        setLoading(true);
        await getShortcutsAllies();
        setOpen(true);
    }

    const handleGoToAllyNeo = async (ally) => {
        setLoading(true)
        let neoUser;
        try {
            neoUser = await AllysServiceSDK.validateNeoUser({ studentid: user.email, ou: ally.company_id })
        } catch (error) {
            log('Error valited user', error);
        }
        if (neoUser?.neo_id) {
            log(neoUser)
            const neoCourses = await AllysServiceSDK.getNeoCoursesByUser({ studentid: neoUser.neo_id, ou: ally.company_id });
            log(neoCourses)
            if (neoCourses.length > 0) {
                const body = {
                    person_id: user.person_id,
                    email: user.email,
                    ou: getOU(user.email),
                    source_id: ally.source_id,
                    company_id: getOU(user.email),
                    course_id: null
                }
                const win = window.open(window.location.protocol + '//' + window.location.host + '/redireccionando/' + convertOuToSource(ally.company_id), "_blank");

                var urlRespuesta = await getConnectionAllies(body);
                log('respuesta allies', urlRespuesta)
                setTimeout(() => {
                    win.focus();
                    win.location.replace(urlRespuesta);
                }, 3000);
                setLoading(false)
                setIsRedirected(true)
            }
            else {
                log('No cursos en Neo')
                const error = {
                    reason: 'Sin Cursos en Neo',
                    end: true
                }
                setIsRedirected(false)
                log('redirigido NO')
                setOpen(true)
                handleCourseSysError(error)
                const log = { "person_id": user.person_id, "errortype_id": 1, "errororigin_id": 1 }
                await logsServiceSDK.logError(log)
                setLoading(false)
            }
        }
        else {
            const error = {
                reason: 'Alumno no encontrado en Neo',
                end: true
            }
            handleCourseSysError(error)
            const log = { "person_id": user.person_id, "errortype_id": 3, "errororigin_id": 1 }
            await logsServiceSDK.logError(log)
            setLoading(false)
        }
    }

    const handleClickHHOnline = async () => {
        setLoadingHHOnline(true);
        const responseVoxyAccessValidation = await VoxyServiceSDK.accessValidation({
            person_id: user.person_id,
            email_address: user.email,
            has_created_a_relationship: voxyUserData.has_created_a_relationship,
            external_user_id: voxyUserData.external_user_id
        });
        log(responseVoxyAccessValidation);
        if (responseVoxyAccessValidation.access) {
            const params = {
                person_id: user.person_id,
                ally: 'Voxy',
                source_id: SOURCES.VOXY,
                course_id: null,
                email: user.email
            }
            const window_ = window.open(window.location.protocol + '//' + window.location.host + '/redireccionando/' + convertOuToSource('HH'), "_blank");
            const urlRespuesta = await getConnectionAllies(params);
            log({ urlRespuesta });
            if (urlRespuesta.includes('https://retail.harmonhallonline.com.mx')) {
                setTimeout(() => {
                    window_.focus();
                    window_.location.replace(urlRespuesta ? urlRespuesta : window.location.protocol + '//' + window.location.host + CURSOS);
                }, 3000);
            }
            else {
                setHHOnlineDialogOpen(true);
                setRedirectHHOnlineMessageError(urlRespuesta);
            }
        }
        else {
            setHHOnlineDialogOpen(true);
            setRedirectHHOnlineMessageError(responseVoxyAccessValidation.message);
        }
        setLoadingHHOnline(false);
    };

    const handleCloseHHOnlineDialog = () => {
        setRedirectHHOnlineMessageError('');
        setHHOnlineDialogOpen(false);
    };

    const handleSendTicket = async () => {
        setLoading(true)
        log('Send ticket')
        const userReq = {
            user: {
                email: user.email,
                name: user.userName
            }
        }
        const zndeskUser = await HelpCenterServiceSDK.getZendeskUser(userReq).then(usr => usr);
        const ticketPayload = {
            ticketData: {
                zendeskId: zndeskUser.id,
                comment: 'No se muestran los cursos en progreso de NEO en la plataforma Talisis',
                subject: 'Error - No se muestran los cursos',
                custom_fields: [
                    {
                        id: "360048350694",
                        value: user.student_id ? user.student_id : ''
                    },
                    {
                        id: '8667463356439',
                        value: 'plataformas_clase'
                    },
                    {
                        id: '8529666934935',
                        value: 'neo'
                    }
                ],
                tags: [
                    "plataforma",
                    "web",
                    "talisis"
                ]
            }
        }
        const ticket = await HelpCenterServiceSDK.postTicket(ticketPayload).then(tckt => tckt)
        setLoading(false)
        log(ticket)
        setTicketSended(true)
    }

    const handleCloseMembershipRenovationMessage = () => {
        setMembershipRenovationCopyOpen(false);
    };

    const manuallyAttemptMembershipPayment = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            setLoadingRepurchase(true);
            const productInfo = await ProductServiceSDK.getProductById(userMembership?.product_id);
            log("productInfo:", productInfo);
            // Preparar los productos para la recompra manual
            const products = [];
            products.push({
                "product_id": productInfo.id,
                "product_name": productInfo.name,
                "product_image_url": productInfo?.image_url,
                "product_description": productInfo?.description,
                "product_type_id": productInfo?.product_type_id,
                "quantity": 1,
                "product_price_id": productInfo?.product_price_id,
                "unit_price": productInfo?.list_price,
                "discount": 0
            });
            // Agregar el add-on a la recompra si el usuario ya contaba con uno 
            if (userAddOn) {
                products.push({
                    "product_id": userAddOn.id,
                    "product_name": userAddOn.name,
                    "product_image_url": userAddOn.image_url,
                    "product_description": userAddOn.description,
                    "product_type_id": userAddOn.product_type_id,
                    "quantity": 1,
                    "product_price_id": userAddOn.product_price_id,
                    "unit_price": userAddOn.list_price,
                    "discount": 0
                });
            }
            // Agregar productos carrito
            const response = await addProductsToCart({
                cart_id: cart?.id,
                products: [...products]
            });
            // Event Tracker v2
            if (userAddOn) {
                handleMembershipAddonEventTrack(productInfo);
            } else {
                handleMembershipRepurchaseEventTrack(productInfo);
            }
            // Actualizar estado reducer del carrito
            dispatchCart(applyCart(response));
            dispatchCart(applyCartProducts(response.cart_details));
            setLoadingRepurchase(false);
            // Redireccionar al carrito para ver el detalle.
            history.push(CART);
        } catch (e) {
            log("error", e);
        }
    };

    const handleMembershipRepurchaseEventTrack = (product) => {
        try {
            const eventData = {};
            eventData['nombreArticulo'] = product?.name;
            eventData['precioArticulo'] = product?.list_price;
            eventData['articuloType'] = "membresías";
            eventData['userId'] = user?.matricula || user?.person_id || "";
            eventData['subscriptionType'] = getNameMembership(product?.name);
            eventData['periodoPago'] = getPeriodMembership(product?.sku);
            eventTracker('agregar_membresia', eventData, ['mixpanel', 'hubspot', 'google']);
        } catch (e) {
            log("event tracker error", e);
        }
    };

    const handleMembershipAddonEventTrack = (standalone) => {
        try {
            const eventData = {};
            eventData['nombreArticulo'] = userAddOn?.name;
            eventData['precioArticulo'] = userAddOn?.list_price + standalone?.list_price;
            eventData['userId'] = user?.matricula || user?.person_id || "";
            eventData['articuloType'] = "addOn"
            eventData['subscriptionType'] = getNameMembership(standalone?.name);
            eventTracker('agregar_membresia_addon', eventData, ['mixpanel', 'hubspot', 'google']);
        } catch (e) {
            log("event agregar_membresia_addon error", e);
        }
    };

    const noCoursesProps = {
        userHasCourses,
        handleGoToNeo,
        handleGoToAllyNeo,
        allies,
        coursesSysError,
        setCoursesSysError,
        handleSendTicket,
        open,
        setOpen,
        loading,
        ticketSended,
        isRedirected
    }

    const levels = programsInProgress.programs.map(it => it.level_id);

    return (
        <React.Fragment>
            <OnStartMyClasses user={user} levels={levels}/> 
            {
                hasRole(ROLE_ACADEMY) &&
                <AcademiesSection user={user} />
            }
            {!loading && (userMembership && userMembership.id > MEMBERSHIPS.FREEMIUM && (!userMembership.is_active || userMembership?.subscription_past_due)) &&
                <Collapse in={membershipRenovationCopyOpen}>
                    <Loader loading={loadingRepurchase} transparent={true} width="100%">
                        <Alert variant="filled" severity="info" color="info" icon={<i class="ri-error-warning-fill text-warning-400"></i>} className="mt-3">
                            <Typography component="div" variant="body2" className="semi-bold">Desafortunadamente no pudimos realizar el cobro para la renovación de tu membresía.</Typography>
                            <Box display="flex">
                                <Typography component="p" variant="body2" className="mt-2 text-warning-100" style={{ width: '90%' }}>
                                    Para renovar tu suscripción <Link href="" variant="body2" className="text-orange-300" onClick={manuallyAttemptMembershipPayment}>Haz clic aquí</Link>. Si ya realizaste tu pago <Link href="#" variant="body2" className="text-orange-300" onClick={() => {
                                        const talisisHelpDeskButton = document.getElementById('talisis_helpdesk_button');
                                        if (talisisHelpDeskButton) {
                                            talisisHelpDeskButton.click();
                                        }
                                    }}>Contáctanos</Link> para que nuestro equipo de soporte te ayude a renovar tu membresía.
                                </Typography>
                                <Typography component="p" align="right" style={{ width: '10%' }}>
                                    <Button className={classes.closeMembershipAlertButton} onClick={handleCloseMembershipRenovationMessage} endIcon={<i class="ri-close-line font-size-10"></i>}>CERRAR</Button>
                                </Typography>
                            </Box>
                        </Alert>
                    </Loader>
                </Collapse>
            }
            <CoursesProvider>
                <Loader loading={loading} transparent={true} minHeight="290px" width={!loading ? "100%" : "95%"} style={{ marginLeft: "auto", marginRight: "auto" }}>
                    {!loading && userRole !== "Maestro" && !userHasCourses && voxyUserData?.has_created_a_relationship && <VoxyHHCard classes={classes} onClick={handleClickHHOnline} mt={3} loading={loadingHHOnline} />}
                    {
                        (!userHasCourses || userRole === "Maestro") && !loading && !voxyUserData?.has_created_a_relationship &&
                        <BannerNotCourses />
                    }
                    <Box className={classes.root}>
                        <Box position="relative" mt={2}>
                            {
                                programsInProgress.programs.map(program => {
                                    return (<Box position="relative" className={[classes.hover_carousel, "mb-4"]}>
                                        <Typography variant="overline" className={"overline-small text-gray-500"}>Programa inscrito:</Typography>
                                        {
                                            !program.courses.length ? 
                                            <>
                                                <Box display='flex' justifyContent='space-between' alignItems='center' className='flex-xs-column align-items-xs-end'>
                                                    <Typography variant="h3">{program.program_name}</Typography>
                                                    <Hidden xsDown>
                                                        <Button variant='contained' onClick={(e) => handleChangePage(e, 'programas', program.id, program.student)}>Ver todas las materias</Button>
                                                    </Hidden>
                                                    <Hidden smUp>
                                                        <Button color='primary' 
                                                            endIcon={<Icon className="ri-arrow-right-s-line"/>}
                                                            onClick={(e) => handleChangePage(e, 'programas', program.id, program.student)}>Ver todos</Button>
                                                    </Hidden>
                                                </Box>
                                                {
                                                    !(program.learning_style === "asincronico" && program.remaining_credits > 0) &&
                                                    <BannerNotCourses />
                                                }
                                            </>
                                            : <Typography className={classes.title} variant="h3"><TitleLink textPrimary={program.program_name} className={classes.titlesin} textSecondary="Ver materias" onClick={(e) => handleChangePage(e, 'programas', program.id, program.student)} /></Typography>
                                        }
                                        {program.learning_style === "asincronico" && <Box display={"flex"} gridGap={16} mb={0.5}><Typography variant="overline" className={[classes.title, 'variant-overline semibold text-blue-300']}>Créditos disponibles: {program.remaining_credits}</Typography> <Icon className="ri-information-line text-warning-300 font-size-16" /> </Box>}
                                        <SlickCarousel infinite="false" length={program.courses.length} typeCard="normal" slidestoshow={1} slidestoscroll={1} variablewidth='true' slidemargin="0px">
                                            {
                                                program.courses.map((courseItem, index) => {
                                                    return (<SwiperSlide key={courseItem?.course_id} className={classes.card}>
                                                        <Suspense fallback={<div></div>}>
                                                            {
                                                                (!courseItem.hasOwnProperty('period') || (isAvailableCourseByDate(localDate, moment.utc(courseItem.period.start_date)))) &&
                                                                <CourseProgressCardBody
                                                                    type_course="inProgram"
                                                                    schedule_hrs={courseItem?.schedule_hrs}
                                                                    schedule_days={courseItem?.schedule_days}
                                                                    materiaOrCourse={courseItem?.program_name}
                                                                    course_url_neo={courseItem?.course_url_neo}
                                                                    source_id={courseItem?.source_id}
                                                                    course_url={courseItem?.course_url}
                                                                    noCarusel={true}
                                                                    course_id={courseItem?.course_id}
                                                                    enrollment_status_id={courseItem?.enrollment_status_id == null ? 3 : courseItem?.enrollment_status_id}
                                                                    course_rating={courseItem?.course_rating != null ? courseItem?.course_rating : 0}
                                                                    index={index}
                                                                    user={user}
                                                                    person_id={user?.person_id}
                                                                    progress_percentage={courseItem?.progress_percentage == null ? 0 : courseItem?.progress_percentage}
                                                                    media={{
                                                                        isProgress: true,
                                                                        index: index,
                                                                        source: {
                                                                            id: convertOuToSource(courseItem.company_id, courseItem.source_id),
                                                                            icon: 'desktop_windows',
                                                                            modality: courseItem?.modality_id != null ? courseItem?.modality_id : 2,
                                                                        },
                                                                        image: courseItem?.image_url,
                                                                        company_id: courseItem?.company_id,
                                                                    }}
                                                                    content={{
                                                                        isProcess: true,
                                                                        course_id: courseItem?.course_id,
                                                                        progress_percentage: courseItem?.progress_percentage == null ? 0 : courseItem?.progress_percentage,
                                                                        enrollment_status_id: courseItem?.enrollment_status_id == null ? 3 : courseItem?.enrollment_status_id,
                                                                        title: courseItem.course_name,
                                                                        hiddeLabel: true,
                                                                    }}
                                                                />
                                                            }
                                                            {
                                                                courseItem.hasOwnProperty('period') && (isDisabledCourseByDate(localDate, moment.utc(courseItem.period.start_date))) &&
                                                                <DisabledCourseCard
                                                                    courseId={courseItem.course_id}
                                                                    sourceIdLogo={convertOuToSource(courseItem.company_id, courseItem.source_id)}
                                                                    media={{
                                                                        backgroundImage: courseItem.image.default,
                                                                        modalityId: courseItem?.modality_id != null ? courseItem?.modality_id : 2,
                                                                    }}
                                                                    content={{
                                                                        courseName: courseItem.course_name,
                                                                        dateAvailableFrom: moment.utc(courseItem.period.start_date).format(COMPLETE_DATE_FORMAT_SHORT)
                                                                    }}
                                                                />
                                                            }
                                                        </Suspense>
                                                    </SwiperSlide>
                                                    )
                                                })
                                            }
                                            {
                                                (program.learning_style === "asincronico" && program.remaining_credits > 0) && <SwiperSlide key='enroll-class' className={classes.card}>
                                                    <Suspense fallback={<div></div>}>
                                                        <CourseEnrollAsyncClass {...program} />
                                                    </Suspense>
                                                </SwiperSlide>
                                            }
                                        </SlickCarousel>
                                    </Box>
                                    )
                                })
                            }
                        </Box>
                        <Box position="relative">
                            {
                                coursesEduconInProgress.courses.map(courseEducon => {
                                    return (<Box position="relative" className={[classes.hover_carousel, "mb-4"]}>
                                        <Typography className={classes.title} variant="h3">
                                            {(courseEducon.show_everything) ? <TitleLink textPrimary={courseEducon.name} className={classes.titlesin} textSecondary="Ver todos" onClick={(e) => handleChangePage(e, 'educon', courseEducon.id)} /> : courseEducon.name}
                                        </Typography>
                                        <SlickCarousel length={courseEducon.courses.length} typeCard="normal" slidestoshow={1} slidestoscroll={1} variablewidth='true' slidemargin="0px">
                                            {
                                                courseEducon.courses.map((courseItem, index) => {
                                                    return (<SwiperSlide key={courseItem?.course_id} className={classes.card}>
                                                        <Suspense fallback={<div></div>}>
                                                            <CourseProgressCardBody
                                                                type_course="inEducon"
                                                                course_url_neo={courseItem?.course_url_neo}
                                                                source_id={courseItem?.source_id}
                                                                course_url={courseItem?.course_url}
                                                                third_party_id={courseItem?.third_party_id}
                                                                noCarusel={true}
                                                                course_id={courseItem?.course_id}
                                                                enrollment_status_id={courseItem?.enrollment_status_id == null ? 3 : courseItem?.enrollment_status_id}
                                                                course_rating={courseItem?.course_rating != null ? courseItem?.course_rating : 0}
                                                                index={index}
                                                                user={user}
                                                                person_id={user?.person_id}
                                                                progress_percentage={courseItem?.progress_percentage == null ? 0 : courseItem?.progress_percentage}
                                                                media={{
                                                                    isProgress: true,
                                                                    index: index,
                                                                    source: {
                                                                        id: convertOuToSource(courseItem.company_id, courseItem.source_id),
                                                                        icon: 'desktop_windows',
                                                                        modality: courseItem?.modality_id != null ? courseItem?.modality_id : 2,
                                                                    },
                                                                    image: courseItem?.image_url,
                                                                    company_id: courseItem?.company_id,
                                                                }}
                                                                content={{
                                                                    isProcess: true,
                                                                    course_id: courseItem?.course_id,
                                                                    progress_percentage: courseItem?.progress_percentage == null ? 0 : courseItem?.progress_percentage,
                                                                    enrollment_status_id: courseItem?.enrollment_status_id == null ? 3 : courseItem?.enrollment_status_id,
                                                                    title: courseItem.name,
                                                                    hiddeLabel: true,
                                                                }}
                                                            />
                                                        </Suspense>
                                                    </SwiperSlide>
                                                    )
                                                })
                                            }
                                        </SlickCarousel>
                                    </Box>
                                    )
                                })
                            }
                        </Box>
                        <Box position="relative">
                            {
                                courseAllysInProgress.courses.map(course => {
                                    return (<Box className={[classes.hover_carousel, "mb-4"]} key={course.id}>
                                        <Typography className={classes.title} variant="h3">
                                            {(course.show_everything) ? <TitleLink textPrimary={course.name} className={classes.titlesin} textSecondary="Ver todos" onClick={(e) => handleChangePage(e, 'aliados', course.id)} /> : course.name}
                                        </Typography>
                                        <SlickCarousel length={course.courses.length} typeCard="normal" slidestoshow={1} slidestoscroll={1} variablewidth='true' slidemargin="0px">
                                            {
                                                course.courses.map((courseItem, index) => {
                                                    return (<SwiperSlide key={courseItem?.course_id} className={classes.card}>
                                                        <Suspense fallback={<div></div>}>
                                                            <CourseProgressCardBody
                                                                type_course="inAllys"
                                                                duration={courseItem?.duration}
                                                                course_url_neo={courseItem?.course_url_neo}
                                                                source_id={courseItem?.source_id}
                                                                course_url={courseItem?.course_url}
                                                                noCarusel={true}
                                                                course_id={courseItem?.course_id}
                                                                enrollment_status_id={courseItem?.enrollment_status_id == null ? 3 : courseItem?.enrollment_status_id}
                                                                course_rating={courseItem?.course_rating != null ? courseItem?.course_rating : 0}
                                                                index={index}
                                                                user={user}
                                                                person_id={user?.person_id}
                                                                progress_percentage={courseItem?.progress_percentage == null ? 0 : courseItem?.progress_percentage}
                                                                media={{
                                                                    isProgress: true,
                                                                    index: index,
                                                                    source: {
                                                                        id: convertOuToSource(courseItem.company_id, courseItem.source_id),
                                                                        icon: 'desktop_windows',
                                                                        modality: courseItem?.modality_id != null ? courseItem?.modality_id : 2,
                                                                    },
                                                                    image: courseItem?.image_url,
                                                                    company_id: courseItem?.company_id,
                                                                }}
                                                                content={{
                                                                    isProcess: true,
                                                                    course_id: courseItem?.course_id,
                                                                    progress_percentage: courseItem?.progress_percentage == null ? 0 : courseItem?.progress_percentage,
                                                                    enrollment_status_id: courseItem?.enrollment_status_id == null ? 3 : courseItem?.enrollment_status_id,
                                                                    title: courseItem.name,
                                                                    hiddeLabel: true,
                                                                }}
                                                            />
                                                        </Suspense>
                                                    </SwiperSlide>
                                                    )
                                                })
                                            }
                                        </SlickCarousel>
                                    </Box>
                                    )
                                })
                            }
                        </Box>
                        {/* Voxy Harmon Hall online */}
                        {!loading && userRole !== "Maestro" && userHasCourses && voxyUserData?.has_created_a_relationship && <VoxyHHCard classes={classes} onClick={handleClickHHOnline} loading={loadingHHOnline} />}
                    </Box>
                </Loader>
                <Dialog open={hhOnlineDialogOpen} onClose={handleCloseHHOnlineDialog} className={classes.HH_online_dialog}>
                    <DialogTitle>
                        <Typography variant="h3">¡Ha ocurrido un error!</Typography>
                    </DialogTitle>
                    <Box className={classes.HH_online_dialog_message_error}>
                        <img loading="lazy" src={cursosError} className="HH_online_dialog_img_error"/>
                        <Typography variant="body2" color="textSecondary">{redirectHHOnlineMessageError}</Typography>
                    </Box>
                </Dialog>
            </CoursesProvider>
        </React.Fragment>
    )
}

export default CardsCoursesInProgress;