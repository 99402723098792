import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';

import ResponsiveContainer from '../../../shared/components/ResponsiveContainer/ResponsiveContainer';
import { eventTracker } from '../../../shared/components/EventTracker/EventTracker';

// styles
import useStyles from '../../../assets/styles/jss/pages/exploreHome/ourProgramsStyle';

import { v4 as uuid } from 'uuid';
import DsIcon from '../../../design-system/components/DsIcon/DsIcon';

import * as paths from '../../../routes/paths'

//sdk
import { ProgramService } from '@sdk-point/talisis';

const ProgramServiceSDK = new ProgramService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);


//Funcion para consultar el numero de cursos disponibles por nivel
const countPrograms = async (level) => {
    let numProgramas;

    //espera la respuesta desde el SDK
    const response = await ProgramServiceSDK.getProgramsNumberByOU('ALL');

    //En caso de maestria se suman los valores de PO y MA
    if(level === 'MA'){
        let numPO = response.number_of_programs.filter((program) => program.level_id ==='PO');

        let numMA = response.number_of_programs.filter((program) => program.level_id ==='MA');

        numProgramas = numMA[0].total + numPO[0].total;
        
        return numProgramas;
    }
    
    //filtra la consulta por nivel deseado
    numProgramas = response.number_of_programs.filter((program) => program.level_id === level);

    //regresa el numero total de cursos
    return numProgramas[0].total;
}

const OurPrograms = () => {
    const history = useHistory();
    const classes = useStyles();
    const [programs, setPrograms] = useState([]);

    useEffect(() => {
        const fetchPrograms = async () => {

            const programData = [
                { title: 'Bachillerato', level: 'BA', border: '#FF7E00', goTo: `programa/706` },
                { title: 'Licenciatura', level: 'LI', border: '#F6BE00', goTo: `${paths.BUSQUEDA}?licenciatura=true` },
                { title: 'Maestría', level: 'MA', border: '#655DC6', goTo: `${paths.BUSQUEDA}?maestria=true` },
            ];

            const programsWithCounts = await Promise.all(programData.map(async (program) => {
                const numProgram = await countPrograms(program.level);
                return { ...program, numProgram };
            }));

            setPrograms(programsWithCounts);
        };

        fetchPrograms();
    }, []);

    const goTo = (event, path, title) => {
        const eventData = {};
        eventData['source'] = 'landing';
        let eventName = '';
        const nameProveedorEvent = ['mixpanel','google'];
        if (path === paths.MEMBRESIAS) {
            nameProveedorEvent.push('hubspot')
            eventName = 'ver_membresías';
        }
        if(title){
            const ofertaEducativa = title.toLowerCase().replace('í','i');
            eventData['ofertaEducativa'] = ofertaEducativa;
            eventData['texto'] = 'si';
            eventName = 'busqueda';
        }
        eventTracker(eventName, eventData, nameProveedorEvent);
        history.push(path);
    };
    
    return (
        <ResponsiveContainer className='bg-dark-black-500'>
            <Box className={classes.root}>
                <Typography variant='h1'>Explora nuestros programas</Typography>
                <Box className={classes.cards}>
                    {
                        programs.map(pObj =>                         
                            <Box className={classes.card} key={uuid()} onClick={(event) => goTo(event, pObj.goTo, pObj.title)}>
                                <Box className={classes.border} style={{background: pObj.border}}/>
                                <Box className={classes.cardContent}>
                                    <Typography variant='h3'>{pObj.title}</Typography>
                                    <Chip className='chip1-fs-300' label={`${pObj.numProgram} programas`} />
                                </Box>
                            </Box>
                        )
                    }
                </Box>
                <Box>
                    <Button color='primary' endIcon={<DsIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" icon='t-arrow-right-line' />} onClick={(event) => goTo(event, paths.MEMBRESIAS)}>Ver membresías</Button>
                </Box>
            </Box>
        </ResponsiveContainer>
    );
};

export default OurPrograms;