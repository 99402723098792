import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// material
import { Box, Button, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core';

// style
import useStyles from '../../../../../assets/styles/jss/pages/account/billing/paymentMethodsListStyle';

const CASH_AND_CHECK_COMP = 'cashAndCheckMethod';
const TRANSFER_COMP = 'transferMethod';
const CARD_COMP = 'cardMethod';

const items = [
    {
        value: CARD_COMP,
        label: 'Pago con Tarjeta de crédito',
        key: 1,
        icon: <i className="ri-wallet-3-line offset-margin-left-10 offset-margin-right-10"></i>
    },
    // {
    //     value: TRANSFER_COMP,
    //     label: 'Pago por transferencia',
    //     key: 2,
    //     icon: <i className="ri-swap-box-line offset-margin-left-10 offset-margin-right-10"></i>
    // },
    // {
    //     value: CASH_AND_CHECK_COMP,
    //     label: 'Pago con efectivo y cheques',
    //     key: 3,
    //     icon: <i className="ri-bank-card-fill offset-margin-left-10 offset-margin-right-10"></i>
    // },
    
];


const PaymentMethodsList = ({onClickReturnButton, onClickSelectMethod}) => {
    const classes = useStyles();

    const [paymentTypeValue, setPaymentTypeValue] = useState("");

    const handleChangeRadio = (event) => {
        setPaymentTypeValue(event.target.value);
    };
    
    const handleClickSelectMethod = () => {
        onClickSelectMethod(paymentTypeValue);
    };
    
    useEffect(() => {
        if (!!items.length) {
            setPaymentTypeValue(items[0].value);
        }
    }, []);
    

    return (
        <>
            <Box>
                <Grid container direction="row">
                    <Grid item xs={12} style={{ paddingBottom: '15px' }}>
                        <Typography variant='h4' className={`text-violet-300`}>Selecciona tu método de pago</Typography>
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: '32px' }}>
                        <Grid container direction="row">
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue={CASH_AND_CHECK_COMP} name="radio-buttons-group" onChange={handleChangeRadio} value={paymentTypeValue}>
                                {
                                    items.map(item => (
                                        <Grid container key={item.key}>
                                            <Grid item xs="auto">
                                            {/* <Box display={'flex'} alignItems={'center'}><Radio color="primary" className={`${classes.radioPaymentMethod}`}/>{item.icon}</Box> */}
                                                    <FormControlLabel value={item.value} control={<Radio color="primary" className={`${classes.radioPaymentMethod}`}/>} sx={{ '& label:': { width: '100vw' } }} style={{ fontSize: '14px', marginBottom: '20px' }} label={item.label} />
                                            </Grid>
                                        </Grid>
                                    ))
                                }
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button size="medium" variant="contained" label="Regresar" onClick={onClickReturnButton} className={'offset-margin-right-28'}>Regresar</Button>
                        <Button size="medium" variant="contained" color='primary' onClick={handleClickSelectMethod}>Siguiente <i className="ri-arrow-right-s-line"></i></Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

PaymentMethodsList.propTypes = {
    onClickReturnButton: PropTypes.func.isRequired, 
    onClickSelectMethod: PropTypes.func.isRequired
};

export default PaymentMethodsList;