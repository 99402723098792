import React, {useEffect} from 'react';
import PropTypes from 'prop-types'
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'

// @material-ui/core
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import Icon from "@material-ui/core/Icon";
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// core components
import Logo from './components/Logo'
import NavLinks from './components/NavLinks'
import NotificationTest from './components/NotificationsTest/NotificationTest';
import RefferalPlanBanner from '../../../pages/ReferralPlan/components/RefferalPlanBanner'

// style
import useStyles from '../../../assets/styles/jss/layout/navbarStyle';
import clsx from 'clsx';
import Notifications from '../../components/Navbar/components/Notifications/Notifications';
import FloatingMenu from './components/FloatingMenu/FloatingMenu';
import FloatingContainerMenu from './components/FloatingContainerMenu/FloatingContainerMenu';

// routes
import * as routerlinks from '../../../routes/routelinks'
import * as paths from '../../../routes/paths'
import ProfileMenu from './components/ProfileMenu/ProfileMenu';

import {MEMBERSHIPS} from '../../../shared/constants/memberships'
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { eventTracker, eventData } from '../../../shared/components/EventTracker/EventTracker';
import { hasRole } from '../../../services/RoleService';
import { ROLE_PROMOTOR, PERMISSION_MY_TALISIS } from '../../../shared/constants/roles';
import { hasPermission } from '../../../services/RoleService';
import HeaderOUS from './components/HeaderOUS';

import SimpleSearchWithFilter from '../../../shared/components/SimpleSearchWithFilter/SimpleSearchWithFilter';
import SearchButton from './components/SearchButton/SearchButton';
import CountdownHotSale from './components/CountdownHotSale/CountdownHotSale';
import { ACTIVATE_COUNTDOWN } from '../../../shared/constants/promotional-banner';


const EXPLORAR_ID = 2;
const MY_TALISIS_ID = 6;

const Navbar = (props) => {
    const {hideNavLinks, isHome, isLogged, isCheckout, isReferrals, open, showLoader, onChangeState, ...rest} = props;
    const { user, roles } = useSelector((state) => ({...state.userReducer, ...state.permissionReducer}));
    const history = useHistory();
    const location = useLocation();

    const classes = useStyles({isLogged});
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [openMenu, setOpenMenu] = React.useState(false);
    const [displayBanner, setDisplayBanner] = React.useState(false);
    const {restrictions} = useSelector((state) => state.userReducer.user);
    const wrapperRef = React.useRef(null);
    const [menu, setMenu] = React.useState([
        {id:1, label: 'Inicio', path: paths.HOME, notification: restrictions && restrictions.length, display: true},
        {id: EXPLORAR_ID, label: 'Explorar', path: '/',isNested: true, open: false, display: true},
        {id:3, label: 'Mis clases', path: routerlinks.CURSOS, display: true},
        // {id:4, label: 'Asesorías', path: paths.ASESORIAS, display: true},
        // {id: 5, label: 'Conecta', path: paths.SIMPLICITY, display: true},
        {id: MY_TALISIS_ID, label: 'My Talisis', href: paths.MY_TALISIS, display: false},
        {id: '25.1.2', label: 'Trámites', path: routerlinks.SOLICITUDES, notification: 0, display: true},
    ])
    const [navItemsNotLogged, setNavItemsNotLogged] = React.useState([
        {id: EXPLORAR_ID, label: 'Explorar', path: '/', isNested: true, open: false, display: true},
        // {id: 1, label: 'Conecta', path: paths.SIMPLICITY, display: true},
        {id: 3, label: 'Eventos', path: paths.EVENTOS, display: true},
        // {id: 5, label: 'Blog', href: paths.BLOG, display: true},
        {id: 4, label: 'Membresías', path: paths.MEMBRESIAS, display: true},
    ]);

    const [state, setState] = React.useState({
        openHotSale: ACTIVATE_COUNTDOWN
    })

    useOutsideAlerter(wrapperRef);

    useEffect(() => {
        if(!isLogged){
            onChangeState('openHotSale', state.openHotSale)
        }
    }, [state])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if(roles.length){
            setDisplayBanner(!hasRole(ROLE_PROMOTOR));
            rolValidator();
        }
    }, [roles]);

    const rolValidator = () => {
        const _menu = [...menu];

        if(hasPermission(PERMISSION_MY_TALISIS)){
            const i = menu.findIndex(it => it.id === MY_TALISIS_ID);
            _menu[i].display = true;

            setMenu(_menu)
        }
    }

    const handleLogin = path => e => {
        const eventData = {};
        eventData['source'] = 'nav_bar';
        if (path===paths.SIGNUP) {
            eventTracker('cta_registro', eventData, ['mixpanel','hubspot','google']);
        }
        else if (path === paths.LOGIN) {
            eventTracker('s_login', {}, ['mixpanel']);
        }
        history.push(path);               
    };

    const handleClickItemNav = (item, e) => {
        e.preventDefault();
        e.stopPropagation();

        
        if(item.id === EXPLORAR_ID){
            if(isLogged){    
                setMenu(prevState => {
                    const listItems = [...prevState];
                    listItems.find(it => it.id === EXPLORAR_ID).open = !item.open;
                    return listItems;
                })
            }else{
                setNavItemsNotLogged(prevState => {
                    const listItems = [...prevState];
                    listItems.find(it => it.id === EXPLORAR_ID).open = !item.open;
                    return listItems;
                })
            }
            setOpenMenu(true);
            eventTracker('explorar', {}, ['mixpanel']);
        }
    }

    const handleHiddeMenu = () => {
        setMenu(prevState => {
            const listItems = [...prevState];
            listItems.find(it => it.id === EXPLORAR_ID).open = false;
            return listItems;
        });
        setNavItemsNotLogged(prevState => {
            const listItems = [...prevState];
            listItems.find(it => it.id === EXPLORAR_ID).open = false;
            return listItems;
        })
        setOpenMenu(false);
    };

    function useOutsideAlerter(ref) {
        React.useEffect(() => {
          /**
           * Alert if clicked on outside of element
           */
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                handleHiddeMenu();
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
    }

    const reloadRedirect = (path, event) => {
        event.preventDefault();
        event.stopPropagation();
        const gotToPathname = path.split("?")[0];
        const currentPath = location.pathname;
    
        if (currentPath === gotToPathname) {
            history.push(path);
            window.location.reload();
        }
        else {
            history.push(path)
        }

        if (path === paths.MEMBRESIAS) {
            const eventData = {};
            eventData['source'] = 'nav_bar';
            eventTracker('ver_membresías', eventData, ['mixpanel','google', 'hubspot']);
        }
        // else if (path === paths.ASESORIAS) {
        //     eventTracker('ver_asesorias', {}, ['mixpanel']);
        // }
        else if(path === routerlinks.CURSOS){
            const eventData = {};
            eventData['source'] = 'nav_bar';
            eventTracker('ver_mis_clases', eventData, ['mixpanel']);
        }
        else if(path === routerlinks.CONECTA){
            eventTracker('conecta', eventData, ['mixpanel']);
        }
    }

    const handleMembershipLanding = () => {
        const eventData = {};
        eventData['source'] = 'nav_bar';
        eventTracker('ver_membresías', eventData, ['mixpanel','google','hubspot']);
    };

    const LogoSection = () => (
        <Box display="flex" alignItems="center">
            <Logo classes={classes.logo} autoResponsive={false}/>
        </Box>
    )

    const LoginSection = () => (
        <Box display="flex" alignItems="center">
            <Hidden smDown >
                <Button
                    color="primary"
                    label="Primario"
                    onClick={handleLogin(paths.SIGNUP)}
                    variant="contained"
                >
                    Regístrate aquí
                </Button>
            </Hidden>
        </Box>
    )

    const handleChangeState = (name, value) => {
        setState({
            ...state,
            [name]: value
        });
    };

    const RenderViewComponents = () => {
        if (showLoader) {
            return (
                <Box flexGrow={1} display="flex" alignItems="center" justifyContent="end">
                    <CircularProgress color=''/>
                </Box>
              )
        }
        if (isReferrals) {
            return <Box>
                <NavLinks goTo={reloadRedirect} onClickItem={handleClickItemNav} className={classes.menu} items={[
                    { id: 1, label: 'Quienes somos', href: "https://quienessomos.talisis.com/", display: true }
                ]} />
            </Box>
        }
        if(isCheckout){
            return (
                <Box flexGrow={1} display="flex" color="white" alignItems="center" justifyContent="end">
                    {/* <Icon className='ri-lock-2-fill font-size-32' /> */}
                    <Icon className='ri-lock-2-fill font-size-32'/>
                </Box>
            )
        }else if(!isLogged){
            return (
                <Box width="100%" flexDirection="row" display="flex" alignItems="center">
                    <Hidden smDown >
                        <Box>
                            <NavLinks goTo={reloadRedirect} onClickItem={handleClickItemNav} className={classes.menu} items={navItemsNotLogged}/>
                        </Box>
                    </Hidden>
                    <Box ml="auto" display="flex">
                        {
                            isResponsive &&
                            <IconButton aria-label="buscar" component={RouterLink} to={paths.BUSQUEDA} className={clsx({[classes.iconButtoFixed]: (location.pathname === paths.BUSQUEDA)}, {'active': (location.pathname === paths.BUSQUEDA)})}>
                                <Icon className="ri-search-line font-size-20 text-dark-black-100"/>
                            </IconButton>
                        }
                        {
                            !isResponsive &&
                            <SearchButton isLogged={isLogged} classes={{[classes.iconButtoFixed]: (location.pathname.startsWith(paths.BUSQUEDA))}}/>
                        }

                        <Hidden mdUp >
                            <IconButton
                                aria-label="open drawer"
                                onClick={props.handleDrawerToggle}
                                edge="start"
                            >
                                <Icon className="ri-menu-line text-gray-100" />
                            </IconButton>
                        </Hidden>
                        <Hidden smDown>
                            <LoginSection />
                        </Hidden>
                    </Box>
                </Box>
            )
        }else{ // Logueado
            return (
                <Box width="100%" flexDirection="row" display="flex" alignItems="center">
                    <Hidden smDown >
                        <Hidden mdDown>
                            {
                                ![MEMBERSHIPS.PREMIUM, MEMBERSHIPS.UNID, MEMBERSHIPS.U_ERRE, MEMBERSHIPS.U_ERRE_EJECUTIVA, MEMBERSHIPS.LITE_PLUS, MEMBERSHIPS.IESALUD, MEMBERSHIPS.HH].some(membership => user.membership_id === membership) &&
                                <Box display="flex" alignItems="center">
                                    <img src="/images/icons/star_4.svg" alt="star-4" className="offset-margin-right-10"/>
                                    <Link variant="caption" color="secondary" onClick={handleMembershipLanding} component={RouterLink} to={paths.MEMBRESIAS}>Mejora tu plan</Link>
                                </Box>
                            }
                        </Hidden>
                        <Box flexGrow={2} display="flex">
                            <NavLinks goTo={reloadRedirect} className={classes.menuLogged} items={menu} onClickItem={handleClickItemNav}/>
                        </Box>
                    </Hidden>
                    <Box ml="auto" display="flex" alignItems="center" justifyContent="end" className="text-dark-black-100">
                        <Hidden smDown>
                            <SearchButton isLogged={isLogged} classes='nav-btn-popover'/>
                        </Hidden>
                        <Box color="grey.gluon">
                            <Hidden mdUp>
                                <IconButton aria-label="buscar" component={RouterLink} to={paths.BUSQUEDA} 
                                className={clsx('nav-btn-popover', {'active': location.pathname === paths.BUSQUEDA})}>
                                    <Icon className="ri-search-line font-size-20 text-dark-black-100"/>
                                </IconButton>
                            </Hidden>
                            <NotificationTest/>
                            <Notifications />
                            <ProfileMenu />
                        </Box>
                    </Box>
                </Box>
            )
        }
    };

    return (
        <AppBar color='inherit' {...rest} elevation={0} className={clsx({'navbar-home': !isLogged})}>
            {!showLoader && <CountdownHotSale open={state.openHotSale} onClose={() => handleChangeState('openHotSale', false)}/>}
            {!showLoader && <HeaderOUS isLogged={isLogged} onClickLogin={handleLogin(paths.LOGIN)}/>}
            <Toolbar className={clsx(classes.toolbar, 'container')}>
                <LogoSection/>
                {
                    !hideNavLinks  && <RenderViewComponents />
                }
                {
                    showLoader && <Box flexGrow={1} display="flex" alignItems="center" justifyContent="end">
                        <CircularProgress color=''/>
                    </Box>
                }
            </Toolbar>
            {   openMenu && 
                <FloatingMenu 
                    handleHiddeMenu={handleHiddeMenu} 
                    wrapperRef={wrapperRef} 
                    isLogged={isLogged}
                    className={clsx({'floating-menu-top': !isLogged && state.openHotSale})}
                />
            }
            {/* {!showLoader && !openMenu && isLogged && <RefferalPlanBanner />} */}
            
        </AppBar>
    );
};

Navbar.propTypes = {
    isHome: PropTypes.bool,
    isLogged: PropTypes.bool,
    isCheckout: PropTypes.bool,
    isReferrals: PropTypes.bool,
    hideNavLinks: PropTypes.bool,
};

export default Navbar;
